@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


/* @font-face {
  font-family: 'mangalfont';
  src: local('mangalfont'), url('../public/font/mangalfont.ttf') format('truetype');
} */
@font-face {
  font-family: "mangalfont";
  src: url("https://demoplus.happyplus.in/images/mangalfont.ttf") format('truetype');
}

.font-face-gm {
  font-family: "mangalfont";
}






/* ======================NEW Dashboard Css Start */

.bg-icon {
  background-color: rgb(33 150 243 / 0.1);
}

.textcolor {
  color: #212121;
}

/* ======================NEW Dashboard Css End */







.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

* {
  font-family: 'Poppins', "Open Sans", sans-serif;
}

.scollar-xs-blue::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  cursor: pointer;
}

.scollar-xs-blue::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.scollar-xs-blue::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #c1c1c1;
  border-radius: 10px;
  margin: 2px;
  padding: 2px;
}

.scollar-xs::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  cursor: pointer;
}

.scollar-xs::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.scollar-xs::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: rgb(226 232 240);
  border-radius: 10px;
}

.lang::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

.lang::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.lang::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #cfd8dc;
}

.customscroll4::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

.customscroll4::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.customscroll4::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bdbdbd;
}

.customscroll5::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

.customscroll5::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.customscroll5::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bdbdbd;
}

.customscroll2::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

.customscroll2::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.customscroll2::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #bdbdbd;
}

.sidebar-scrollar::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  cursor: pointer;
  border-radius: 50px;
}

.sidebar-scrollar::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  background: 0 0;
  cursor: pointer;
}

.sidebar-scrollar::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #e1e8f0;
  border-radius: 50px;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #000;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 120%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.extraClass1,
.extraClass2,
.extraClass3,
.extraClass4,
.extraClass5,
.extraClass6 {
  white-space: nowrap !important;
}

.list-circle {
  list-style-type: circle;
}

.tooltipcard {
  display: none;
}

.tooltipshow:hover .tooltipcard {
  display: block;
}

.tooltipcard .tooltipcard::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: red transparent transparent transparent;
}

input:checked~.dot {
  transform: translateX(125%);
  right: 0.25rem;
  margin-left: 0.25rem;
}

input:checked+.togle-bg {
  background-color: #2196f3;
}

/*circle progress  */



.ql-editor {
  min-height: 80px !important;
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
  /* overflow-x: scroll; */
}


.half-circle {
  width: 350px;
  height: 180px;
  background-color: white;
  border: 20px solid purple;
  border-top-left-radius: 210px;
  border-top-right-radius: 210px;
  border-bottom: 0;
}